<template>
  <b-form @submit.stop.prevent="onSubmit">
    <!-- begin: Email Input  -->
    <b-form-group label="New Password">
      <b-form-input
        class="form-control-solid"
        type="password"
        v-model="$v.form.password.$model"
        :state="validateState('password')"
      ></b-form-input>
    </b-form-group>
    <!-- end: Email Input  -->

    <!-- begin: Email Input  -->
    <b-form-group label="Confirm Password">
      <b-form-input
        class="form-control-solid"
        type="password"
        v-model="$v.form.confirmPassword.$model"
        :state="validateState('confirmPassword')"
      ></b-form-input>
    </b-form-group>
    <!-- end: Email Input  -->

    <!-- begin: Reset Password Button  -->
    <b-button
      variant="primary"
      class="font-size-h6 font-weight-bolder"
      block
      type="submit"
      v-bind:class="{ 'spinner spinner-light spinner-right': isBusy }"
      >{{ $t("AUTH.GENERAL.SUBMIT_BUTTON") }}</b-button
    >
    <!-- end: Reset Password Button  -->
  </b-form>
</template>

<script>
import { validationMixin } from "vuelidate";
import { minLength, required } from "vuelidate/lib/validators";
import swal from "sweetalert";

export default {
  name: "SetNewPasswordForm",
  mixins: [validationMixin],
  beforeMount() {
    this.code = this.$route.query.code;
  },
  data() {
    return {
      form: {
        password: "",
        confirmPassword: "",
      },
      isBusy: false,
      code: "",
    };
  },
  validations: {
    form: {
      password: {
        required,
        minLength: minLength(3),
      },
      confirmPassword: {
        required,
        minLength: minLength(3),
      },
    },
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      const payload = {
        password: this.form.password,
        confirm_password: this.confirmPassword,
        token: this.code,
      };

      this.isBusy = true;
      this.$store
        .dispatch("sendResetNewPassword", payload)
        .then(() => {
          swal({
            title: "Password Reset",
            text: "Password successfully reset. If you're not logged in, use email and the new password to continue using Smartid Corp",
            icon: "success",
          });
          this.$router.push("/login");
        })
        .finally(() => (this.isBusy = false));
    },
  },
};
</script>

<style scoped></style>
