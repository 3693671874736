<template>
  <div class="d-flex justify-content-center">
    <b-card class="card-custom m-5">
      <b-card-title class="card-title">
        <h3 class="card-label font-weight-bolder text-dark text-center">
          Reset Password
        </h3>
      </b-card-title>

      <div class="d-flex mt-5 p-md-5 p-sm-1 flex-column w-md-300px">
        <div class="mx-auto mb-5 svg-icon svg-icon-light-primary svg-icon-5x">
          <inline-svg src="/media/svg/icons/General/User.svg" />
        </div>

        <SetNewPasswordForm></SetNewPasswordForm>
      </div>
    </b-card>
  </div>
</template>

<script>
import SetNewPasswordForm from "@/modules/auth/components/password/SetNewPasswordForm";
export default {
  name: "SetNewPasswordPage",
  components: { SetNewPasswordForm },
  data() {
    return {
      isBusy: false,
    };
  },
};
</script>

<style scoped></style>
